import { TYPE_BROADCAST } from './types';
/**
 * Change text only, do not change text inside {{ }}
 */
export const customText = {
  [TYPE_BROADCAST.ID_CUSTOM_ORANG_TUA]: `Kepada Yth. 
Bapak/Ibu/Saudara/i 
*{{GUEST_NAME}}*
____
   
🕊️🕊️🕊️ 
Tanpa mengurangi rasa hormat,  Kami mengundang Bapak/Ibu/Saudara/i untuk menghadiri acara pernikahan putra putri kami: 
   
*Firhan Ulinuha Fachriansyah* 
Putra dari Bapak Muskan Jumaeri 
& Ibu Ninik Kristinah
💍
*Alya Latifa* 
Putri dari Bapak Arief Noor Raharjo & Ibu Widyorini
  
Yang akan diselenggerakan pada hari: 
🗓️ *Minggu, 12 November 2023* 
💍 *Akad: 07.00 WIB - Selesai (terbatas)* 
🕊️ *Resepsi: 11.00 - 13.00 WIB*
📍 *Gedung Sasana Widya Praja BPSDMD Prov. Jateng (Diklat)*
Jl. Setiabudi No. 201 A Srondol Kulon, Kec. Banyumanik, Semarang, Jawa Tengah 50263
🕊️🕊️🕊️ 
  
Suatu kebahagiaan bagi kami apabila Bapak/Ibu/Saudara/i berkenan untuk hadir dan memberikan doa restu. 
   
*Silahkan konfirmasi kehadiran dengan klik link di bawah ini:* 
{{LINK}}
Terima kasih. 
   
Hormat kami, 
*Bapak Muskan Jumaeri & Ibu Ninik Kristinah*
*Bapak Arief Noor Raharjo & Ibu Widyorini*
`,
  [TYPE_BROADCAST.ID_CUSTOM_PENGANTIN]: `Kepada Yth. 
Bapak/Ibu/Saudara/i 
*{{GUEST_NAME}}*
____
   
🕊️🕊️🕊️ 
Tanpa mengurangi rasa hormat,  Kami mengundang Bapak/Ibu/Saudara/i untuk menghadiri acara pernikahan kami: 
   
*Firhan Ulinuha Fachriansyah* 
Putra dari Bapak Muskan Jumaeri 
& Ibu Ninik Kristinah
💍
*Alya Latifa* 
Putri dari Bapak Arief Noor Raharjo & Ibu Widyorini
  
Yang akan diselenggerakan pada hari: 
🗓️ *Minggu, 12 November 2023* 
💍 *Akad: 07.00 WIB - Selesai (terbatas)*
🕊️ *Resepsi: 11.00 - 13.00 WIB*
📍 *Gedung Sasana Widya Praja BPSDMD Prov. Jateng (Diklat)*
Jl. Setiabudi No. 201 A Srondol Kulon, Kec. Banyumanik, Semarang, Jawa Tengah 50263
🕊️🕊️🕊️
  
Suatu kebahagiaan bagi kami apabila Bapak/Ibu/Saudara/i berkenan untuk hadir dan memberikan doa restu. 
   
*Silahkan konfirmasi kehadiran dengan klik link di bawah ini:* 
{{LINK}}
  
Terima kasih. 
   
Hormat kami, 
*Firhan & Alya*
`,
  //Reminder RSVP

  [TYPE_BROADCAST.ID_REMINDER_RSVP]: `Yth. Bapak/Ibu/Saudara/i,  
*{{GUEST_NAME}}*

Melanjutkan undangan pernikahan kami, *{{BOY_NAME}}* & *{{GIRL_NAME}}*, kami ingin mengingatkan Bapak/Ibu/Saudara/i untuk segera mengisi formulir konfirmasi kehadiran (RSVP) yang ada pada website undangan kami:
{{LINK}} 

Status kehadiran Bapak/Ibu/Saudara/i akan bermanfaat untuk kami dalam mempersiapkan acara pernikahan mendatang. 

Terima kasih atas perhatiannya.

*Kami yang berbahagia,*
_{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & keluarga_
`,

  [TYPE_BROADCAST.EN_REMINDER_RSVP]: `Dear Mr/Mrs/Ms,  
*{{GUEST_NAME}}*

Following up on our wedding invitation, *{{BOY_NAME}}* & *{{GIRL_NAME}}*, we would like to remind you to immediately fill out the attendance confirmation form (RSVP) on our invitation website: 
{{LINK}}

Your attendance status will be very useful for us in preparing the upcoming wedding.

Thank you for your attention

*Yours truly,*
_{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & family_
`,

  [TYPE_BROADCAST.ID_REMINDER_EVENT]: `Yth. Bapak/Ibu/Saudara/i,  
*{{GUEST_NAME}}*

Melalui pesan ini, kami ingin mengingatkan kembali undangan pernikahan kami, *{{BOY_NAME}}* & *{{GIRL_NAME}}* yang diselenggarakan pada: 

Hari: {{BROADCAST_WEDDING_DAY}}
Waktu: {{TIME}} 
Lokasi: {{BROADCAST_WEDDING_LOCATION}}

Besar harapan kami Bapak/Ibu/Saudara/i berkenan menghadiri pernikahan kami.

Terima kasih 

*Kami yang berbahagia,*
_{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & family_

`,
  [TYPE_BROADCAST.EN_REMINDER_EVENT]: `Dear Mr/Mrs/Ms,  
*{{GUEST_NAME}}*

Through this message, we would like to remind you of our wedding invitation, *{{BOY_NAME}}* & *{{GIRL_NAME}}* which will be held on:

Date: {{BROADCAST_WEDDING_DAY}}
Time: {{TIME}}
Place: {{BROADCAST_WEDDING_LOCATION}}

We hope that you are willing to attend our wedding.

Thank you for your attention

*Yours truly,*
_{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & family_
`,

  [TYPE_BROADCAST.ID_THANKS]: `Yth. Bapak/Ibu/Saudara/i,  
*{{GUEST_NAME}}*

Kami, *{{BOY_NAME}}* & *{{GIRL_NAME}}* dan keluarga ingin mengucapkan terima kasih atas kesediaan Bapak/Ibu/Saudara/i untuk hadir dalam acara pernikahan kami. 

Kehadiran Bapak/Ibu/Saudara/i tentunya telah menjadi kebahagiaan untuk kami & keluarga. Kami juga ingin memohon maaf jika selama berlangsungnya acara terdapat hal-hal yang kurang berkenan. 
 
Terima kasih atas segala ucapan, doa, dan perhatian yang diberikan. 

*Kami yang berbahagia,*
_{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & keluarga_

`,

  [TYPE_BROADCAST.EN_THANKS]: `Dear Mr/Mrs/Ms,  
*{{GUEST_NAME}}*

We, *{{BOY_NAME}}* & *{{GIRL_NAME}}* and family would like to thank you for your willingness to attend our wedding.

Your presence has certainly been a joy for us & our family. We also want to apologize if during the event there are things that are not pleasing.
 
Thank you for all the words, prayers, and attention given.

*Yours truly,*
_{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & family_

`,
};
